import { Image } from "../ImageItem";

export interface Project {
  location: string;
  capability: string;
  projectType: string;
  description: string;
  images: Image[];
};

export const capability = [
  "Planning",
  "Certificate of Lawfulness"
];

export const projectType = {
  [capability[0]]: [
    "Rear Extension",
    "Side Extension",
    "House Extension",
    "Rear and Front Extension",
    "Rear and Side Extension",
    "Porch Enlargement",
    "Roof Alteration",
    "Loft Conversion",
    "Rear Extension & Front Porch",
    "Ground floor rear extension, First floor side extension, & porch"],
  [capability[1]]: [
    "Side Extension",
    "House Extension",
    "Loft Conversion",
  ],
};