import React from "react";
import axios from "axios";
import { Loading } from "../components/loading/Loading";
import { IProfile, TableContent } from "../Types";
import { PageContentLayout } from "../components/home/PageContentLayout";
import { drawerTabs } from "./home/HomeData";
import { AddProfile } from "../components/Profile/AddProfile";

export const Profiles = () => {
  const [content, setContent] = React.useState<TableContent>({
    columns: [],
    data: [],
  });
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const createContent = React.useCallback((data: any) => {
    const content: TableContent = {
        columns: [],
        data: [],
    };

    // Moving thumbnail as first property and removing profileId
    // Calculating columns
    content.columns = Object.keys(data[0]).filter((val) => val !== "thumbnail").map((val) => val === "profileId" ? "thumbnail" : val);

    // Calculating data
    data.forEach((item: IProfile) => {
        const { profileId, thumbnail, ...rest } = item;
        const updatedItem = { thumbnail, ...rest };
        content.data.push(Object.values(updatedItem));
    });

    setContent(content);
  }, []);
  
  React.useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BASE_URL}/profiles`)
    .then((response) => {
        if (response.data && response.data.length > 0) {
            createContent(response.data);
        } else {
            setError("No profiles found");
        }
        setLoading(false);
    }).catch(() => {
        setError("Error fetching profiles");
        setLoading(false);
    });
  }, [createContent]);

  if (isLoading)
    return <Loading />;

  if (error)
    return <>{error}</>;

  return (
    <PageContentLayout
      contentType={drawerTabs[3]}
      tableContent={content!}
    >
      <AddProfile />
    </PageContentLayout>
  );
};
