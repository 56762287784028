import axios from "axios";
import { compressImage, CompressionLevel } from "../../utils/ImageCompressor";
import { imageUploader } from "../../utils/ImageUplader";
import { Project } from "./Interface";

export const validationHandler = (values: any) => {
  const errors: { [k: string]: any } = {};
  const requiredField = "Required";

  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key]?.length === 0) {
      errors[key] = requiredField;
      return;
    }
  });
  return errors;
}

export const submitHandler = async ({ capability, description, images, location, projectType }: Project) => {
  const baseUrl = "https://btnu79vaff.execute-api.us-east-1.amazonaws.com/prod";
  const response = await axios.post(`${baseUrl}/save-project`, {
    location,
    capability,
    projectType,
    description,
  });

  const projectId = response.data.projectId;
  const coverImage = images[0];
  const url = `${baseUrl}/save-project-thumbnail/${projectId}`;
  const compressedCoverImage = await compressImage(coverImage.file, CompressionLevel.High);
  await imageUploader(url, compressedCoverImage);

  (images.slice(1)).forEach(async (image) => {
    const compressedImage = await compressImage(image.file, CompressionLevel.Medium);
    const url = `${baseUrl}/save-project-image/${projectId}`;
    await imageUploader(url, compressedImage);
  });
}