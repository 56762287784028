import { Button } from "@mui/material";
import { useState } from "react";
import { BlogForm } from "./BlogForm";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";

export const AddBlog = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}>Add Blog</Button>
      <FullScreenDialog handleClose={() => setOpen(false)} open={open} title={"Add Blog"}>
        <FormPageLayout
          formComponent={<div><BlogForm /></div>} previewComponent={<>{/*WIP */}</>} />
      </FullScreenDialog >
    </>
  );
};
