import Cropper, { ReactCropperElement } from "react-cropper";
import { Image } from "./ImageItem";
import { useFormikContext } from "formik";
import { useRef, useState, useCallback } from "react";
import "cropperjs/dist/cropper.css";

export const ImageCropper = () => {
  const timeoutId = useRef<NodeJS.Timeout>();
  const cropperRef = useRef<ReactCropperElement>(null);
  const [image, setImage] = useState<Image>();
  const { setFieldValue } = useFormikContext<"image">();

  const onCrop = () => {
    if (timeoutId.current)
      clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      const cropper = cropperRef.current?.cropper;
      const imageString = (cropper?.getCroppedCanvas().toDataURL('image/jpeg', 0.8) ?? "");
      setFieldValue("image", imageString);
    }, 300);
  };

  const onAdd = useCallback(async (file: File) => {
    const imageFile: Image = {
      fileName: file.name,
      filePath: URL.createObjectURL(file),
      file
    };
    setImage(imageFile);
  }, []);

  return (
    <>
      <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
        if (e.target.files && e.target.files[0]) {
          onAdd(e.target.files[0]);
        }
      }} />
      <Cropper
        src={image?.filePath}
        style={{ height: 250 }}
        initialAspectRatio={1 / 1}
        aspectRatio={1 / 1}
        guides={false}
        crop={onCrop}
        autoCropArea={0.5}
        autoCrop={true}
        responsive={false}
        background
        ref={cropperRef}
      />
    </>
  );
};
