import axios from "axios";
import { imageUploader } from "../../utils/ImageUplader";
import { Profile } from "./Interface";
import { CompressionLevel, compressImage } from "../../utils/ImageCompressor";

export const validationHandler = (values: any) => {
  const errors: { [k: string]: any } = {};
  const requiredField = "Required";

  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key]?.length === 0) {
      errors[key] = requiredField;
      return;
    }
  });
  return errors;
}

export const submitHandler = async ({ description, bufferString, name, role, sidePose }: Profile) => {
  const baseUrl = "https://btnu79vaff.execute-api.us-east-1.amazonaws.com/prod";
  const response = await axios.post(`${baseUrl}/save-profile`, {
    name,
    role,
    description,
  });

  const profileId = response.data.profileId;
  const thumbnailUrl = `${baseUrl}/save-profile-thumbnail/${profileId}`;
  const formatedString = bufferString.slice(23);
  await imageUploader(thumbnailUrl, formatedString);

  
  const sidePoseUrl = `${baseUrl}/save-profile-sidepose/${profileId}`;
  const compressedImage = await compressImage(sidePose.file, CompressionLevel.Medium);
  await imageUploader(sidePoseUrl, compressedImage);
}