import axios from "axios";
import { imageUploader } from "../../utils/ImageUplader";
import { Blog } from "./Interface";
import { compressImage, CompressionLevel } from "../../utils/ImageCompressor";

export const validationHandler = (values: any) => {
  const errors: { [k: string]: any } = {};
  const requiredField = "Required";

  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key]?.length === 0) {
      errors[key] = requiredField;
      return;
    }
  });
  return errors;
}

export const submitHandler = async ({ content, featured, thumbnail, title }: Blog) => {
  const baseUrl = "https://btnu79vaff.execute-api.us-east-1.amazonaws.com/prod";
  const response = await axios.post(`${baseUrl}/save-blog`, {
    content,
    title,
    featured,
  });

  const blogId = response.data.blogId;
  const url = `${baseUrl}/save-blog-thumbnail/${blogId}`;
  const compressedCoverImage = await compressImage(thumbnail.file, CompressionLevel.Medium);
  await imageUploader(url, compressedCoverImage);
}