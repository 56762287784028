import React from "react";
import axios from "axios";
import { PageContentLayout } from "../components/home/PageContentLayout";
import { drawerTabs } from "./home/HomeData";
import { IProject, TableContent } from "../Types";
import { Loading } from "../components/loading/Loading";
import { convertDateFormat } from "../utils/Utils";
import { AddProject } from "../components/project/AddProject";

export const Projects = () => {
    const [content, setContent] = React.useState<TableContent>({
        columns: [],
        data: [],
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");

    const createContent = React.useCallback((data: any) => {
        const content: TableContent = {
            columns: [],
            data: [],
        };

        // Moving cover as first property and removing projectId
        // Calculating columns
        content.columns = Object.keys(data[0]).filter((val) => val !== "cover").map((val) => val === "projectId" ? "cover" : val);

        // Calculating data
        data.forEach((item: IProject) => {
            item.addedDate = convertDateFormat(item.addedDate);
            const { projectId, cover, ...rest } = item;
            const updatedItem = { cover, ...rest };
            content.data.push(Object.values(updatedItem));
        });
    
        setContent(content);
    }, []);

    React.useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}/projects`)
        .then((response) => {
            if (response.data && response.data.length > 0) {
                createContent(response.data);
            } else {
                setError("No projects found");
            }
            setLoading(false);
        }).catch(() => {
            setError("Error fetching projects");
            setLoading(false);
        });
    }, [createContent]);

    if (isLoading)
        return <Loading />;

    if (error)
        return <>{error}</>;

    return (
        <PageContentLayout
            contentType={drawerTabs[1]}
            tableContent={content!}
        >
            <AddProject />
        </PageContentLayout>
    );
};
