import { Button, Typography } from '@mui/material';
import { Field, Form, Formik } from "formik";
import { TextField } from 'formik-mui';
import { DescriptionBox } from "../DescriptionBox/DescrptionBox";
import { ImageCropper } from "../ImageCropper";
import addProfileStyle from "./AddProfile.module.scss";
import { submitHandler, validationHandler } from "./FormHandler";
import { UploadImage, Image } from '../ImageItem';

type ImageType = Image & undefined;
export const ProfileForm = () => {
  return (
    <Formik
      initialValues={{
        name: "",
        role: "",
        description: "",
        image: "",
        sidePose: undefined as ImageType,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const result = validationHandler(values);
        setErrors(result);
        await submitHandler({ bufferString: values.image, ...values });
        setSubmitting(false);
      }}>
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Form className={addProfileStyle.form}>
          <Typography>Thumbnail</Typography>
          <ImageCropper />
          <Typography>Side pose</Typography>
          <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const imageFile: Image = {
                fileName: file.name,
                filePath: URL.createObjectURL(file),
                file
              }
              setFieldValue("sidePose", imageFile);
            }
          }} />
          {values.sidePose !== undefined && (<UploadImage image={values.sidePose} />)}
          <Field
            className={`${addProfileStyle.spacing}`}
            component={TextField}
            name="name"
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
          />
          <Field
            className={`${addProfileStyle.spacing}`}
            component={TextField}
            name="role"
            label="Role"
            variant="outlined"
            size="small"
            fullWidth
          />
          <DescriptionBox name={"description"} />
          <Button
            className={addProfileStyle.spacing}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            Post
          </Button>
        </Form>
      )
      }
    </Formik >
  );
};
