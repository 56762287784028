import { Table as MuiTable, TableContainer, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React from "react";
import styles from "./Table.module.scss";

const imageRegex = /\.(jpg|png|jpeg)$/i;

export interface TableProps {
  columns: string[];
  data: string[][];
}

export const Table = (props: TableProps) => {
  return (
    <TableContainer className={styles.tableContainer}>
      <MuiTable className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            {props.columns.map((column) => <TableCell>{column}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {props.data.map((row: string[]) => {
            return <TableRow className={styles.tableRow}>
              {row.map((column: string) => <TableCell>{imageRegex.test(column) ? <img className={styles.img} src={column} alt="" /> : column}</TableCell>)}
            </TableRow>
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
