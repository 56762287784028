import { Typography } from '@material-ui/core';
import { Button, MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { DescriptionBox } from '../DescriptionBox/DescrptionBox';
import { Image } from "../ImageItem";
import { UploadImages } from '../UploadImages';
import { submitHandler, validationHandler } from './FormHandler';
import { capability, projectType } from './Interface';
import addProjectStyles from "./AddProject.module.scss";

export const ProjectForm = () => {
  return (
    <Formik
      initialValues={{
        location: "",
        capability: capability[0],
        projectType: "",
        description: "",
        images: [] as Image[],

      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const result = validationHandler(values);
        setErrors(result);
        await submitHandler(values);
        setSubmitting(false);
      }}>
      {({ submitForm, isSubmitting, values }) => (
        <Form className={addProjectStyles.form}>
          <Typography className={addProjectStyles.spacing}>*Note first image will be used as thumbnail</Typography>
          <UploadImages />
          <Field
            className={addProjectStyles.spacing}
            component={TextField}
            name="location"
            label="Location"
            variant="outlined"
            size="small"
            fullWidth
          />
          <br />
          <Field
            className={`${addProjectStyles.spacing} ${addProjectStyles.select}`}
            component={Select}
            id="capability"
            name="capability"
            labelId="capability"
            fullWidth={true}
            size="small"
            label="Capability">
            {capability.map(value => {
              return <MenuItem value={value}>{value}</MenuItem>
            })}
          </Field>
          <br />
          <Field
            className={`${addProjectStyles.spacing} ${addProjectStyles.select}`}
            component={Select}
            id="projectType"
            name="projectType"
            labelId="projectType"
            fullWidth={true}
            size="small"
            label="Project Type">
            {projectType[values.capability].map(value => {
              return <MenuItem value={value}>{value}</MenuItem>
            })}
          </Field>
          <br />
          <DescriptionBox name={"description"} />
          <br />
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            Post
          </Button>
        </Form>
      )}
    </Formik>
  );
};
