import { Button } from "@mui/material";
import { useState } from "react";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import { ProjectForm } from './ProjectForm';

export const AddProject = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}>Add Project</Button>
      <FullScreenDialog handleClose={() => setOpen(false)} open={open} title={"Add Project"}>
        <FormPageLayout
          formComponent={<div><ProjectForm /></div>} previewComponent={<>{/*WIP*/}</>} />
      </FullScreenDialog >
    </>
  );
};
