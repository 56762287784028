import { Button } from "@mui/material";
import { useState } from "react";
import { ProfileForm } from "./ProfileForm";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";

export const AddProfile = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}>Add Profile</Button>
      <FullScreenDialog handleClose={() => setOpen(false)} open={open} title={"Add Profile"}>
        <FormPageLayout
          formComponent={<div><ProfileForm /></div>} previewComponent={<>{/*WIP */}</>} />
      </FullScreenDialog >
    </>
  );
};
