import React from "react";
import { drawerTabs } from "./HomeData";
import styles from "./Home.module.scss";
import { DrawerComponent } from "../../components/drawer/DrawerComponent";
import { Box, Button } from "@mui/material";
import { Projects } from "../Projects";
import { PageContentLayout } from "../../components/home/PageContentLayout";
import { Profiles } from "../Profiles";
import { Blogs } from "../Blogs";

export const Home = () => {
  const [activeTab, setActiveTab] = React.useState(drawerTabs[0]);

  const boxContent = React.useMemo(() => {
    switch(activeTab) {
      case drawerTabs[0]:
        return (
          <PageContentLayout contentType={activeTab} tableContent={{ columns: [], data: [] }}>
            <Button variant="contained" color="primary">{drawerTabs[0]}</Button>
          </PageContentLayout>
        );
      case drawerTabs[1]:
        return <Projects />;
      case drawerTabs[2]:
        return <Blogs />;
      case drawerTabs[3]:
        return <Profiles />;
    }
  }, [activeTab]);

  return (
    <div className={styles.home}>
      <Box component="nav" className={styles.drawerContainer}>
        <DrawerComponent activeTab={activeTab} tabs={drawerTabs} className={styles.drawer} onClick={(selected) => setActiveTab(selected)} />
      </Box>
      <Box className={styles.contentContainer}>
        {boxContent}
      </Box>
    </div>
  );
};
