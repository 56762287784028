export const drawerTabs = [
  "Dashboard",
  "Project",
  "Blog",
  "Profile",
];

export const horizontalTabs = [
  "All",
  "UnApproved",
  "Approved"
];
