import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from 'formik-mui';
import { DescriptionBox } from "../DescriptionBox/DescrptionBox";
import addBlogStyle from "./AddBlog.module.scss";
import { submitHandler, validationHandler } from "./FormHandler";
import { UploadImage, Image } from '../ImageItem';

type ImageType = Image & undefined;
export const BlogForm = () => {
  return (
    <Formik
      initialValues={{
        title: "",
        featured: false,
        content: "",
        thumbnail: undefined as ImageType,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const result = validationHandler(values);
        setErrors(result);
        await submitHandler(values);
        setSubmitting(false);
      }}>
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Form className={addBlogStyle.form}>
          <Typography variant="body1">Blog thumbnail</Typography>
          <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const imageFile: Image = {
                fileName: file.name,
                filePath: URL.createObjectURL(file),
                file
              }
              setFieldValue("thumbnail", imageFile);
            }
          }} />
          {values.thumbnail!==undefined && (<UploadImage image={values.thumbnail} />)}

          <Field
            className={`${addBlogStyle.spacing}`}
            component={TextField}
            name="title"
            label="Title"
            variant="outlined"
            size="small"
            fullWidth
          />
          <Field
            className={`${addBlogStyle.spacing}`}
            component={Checkbox}
            name="featured"
            label="Featured"
            variant="outlined"
            size="small"
            fullWidth
          /> Featured
          <DescriptionBox name={"content"} />
          <Button
            className={addBlogStyle.spacing}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            Post
          </Button>
        </Form>
      )
      }
    </Formik >
  );
};
