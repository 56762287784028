import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import "./styles.scss";

import "react-quill/dist/quill.snow.css";
import { useField, useFormikContext } from "formik";
// eslint-disable-next-line @typescript-eslint/naming-convention
export const DescriptionBox = (props: { name: string }) => {
  const timeoutId = React.useRef<NodeJS.Timeout>();
  const { setFieldValue } = useFormikContext();
  const [ _field, meta ] = useField(props.name);

  const handleChange = (value: string) => {
    if (timeoutId.current)
      clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => setFieldValue(props.name, value), 300);
  };

  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        className={meta.error ? "ql-error" : ""}
        onChange={handleChange}
        placeholder={"Project description..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};
