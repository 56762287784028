import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import loginStyles from "./Login.module.scss";

export const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest)
  }
  return (

    <div>
      <form className={loginStyles.container}>
        <Card className={loginStyles.card}>
          <CardHeader className={loginStyles.title} title="MAPLIN STUDIO ADMIN PORTAL" />
          <CardContent>
            <div>
              <CardActions>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={loginStyles.button}
                  onClick={() => handleLogin()}
                >
                  Login
                </Button>
              </CardActions>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
}